import { InputAdornment, List, ListItem, Stack, styled, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as ZoomIcon } from 'resources/icons/zoom.svg';
import config from 'config';
import { useTranslation } from 'react-i18next';
import SavedAddresses from './SavedAddresses';
import * as geolib from 'geolib';
import { useAuthenticatedUser, useContextRedirection } from 'hooks';
import { useStore } from 'react-redux';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { Address } from 'models/profile.model';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { redesignColors } from 'resources/theme/theme.colors';
import ConfirmDialog from 'components/confirm-dialog';
import api from 'api';
import { CenterUpdateInfo } from 'pages/patient/centers-updates';
import RoutePaths from 'utils/RoutePaths';
import { getAddressString } from './utils';

interface AddressProps {
  nextButtonActive: boolean;
  setNextButtonActive: (state: boolean) => void;
  isCheckin?: boolean;
}

const NEAR_CENTER_DISTANCE_METRES = 5;

const stackStyles = { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 0 };

const StyledAddressInput = styled(TextField)(({ theme }) => ({
  height: '100%',
  backgroundColor: 'white',
  borderRadius: '8px',
  width: '100%',
}));

const AddressComponent: FC<AddressProps> = ({ setNextButtonActive, isCheckin }) => {
  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: config.googleMapsApiKey,
  });

  const { t } = useTranslation();
  const navigate = useContextRedirection();
  const { getState, dispatch } = useStore();
  const authenticatedUser = useAuthenticatedUser();

  const [centerInfos, setCenterInfos] = useState<CenterUpdateInfo[]>([]);

  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [isSelectAddress, setIsSelectAddress] = useState<boolean>(false);
  const [showNoNumberModal, setShowNoNumberModal] = useState<boolean>(false);
  const [showNoPostcodeModal, setShowNoPostcodeModal] = useState<boolean>(false);
  const [showPreRegistrationModal, setShowPreRegistrationModal] = useState<boolean>(false);
  const [searchAddress, setSearchAddress] = useState<string>('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [longLat, setLongLat] = useState({
    long: 0,
    lat: 0,
  });

  useEffect(() => {
    if (!selectedAddress) {
      setNextButtonActive(false);
    }
  }, [setNextButtonActive, selectedAddress]);

  useEffect(() => {
    const storage = !isCheckin ? getState().newAppointmentFlowReducer : null;

    if (!storage) return;
    setSelectedAddress(storage.address.address);

    if (storage.address?.address?.zip_code) {
      setNextButtonActive(true);
    }
    setAdditionalInfo(storage.address.additionalInfo);
    setLongLat(storage.address.longLat);
  }, [setNextButtonActive, setSelectedAddress, isCheckin, getState]);

  useEffect(() => {
    if (!selectedAddress) return;

    dispatch({
      type: NewAppointmentFlowActionType.SET_ADDRESS,
      address: {
        address: selectedAddress,
        additionalInfo,
        longLat,
      },
    });
  }, [selectedAddress, isCheckin, dispatch, additionalInfo, longLat]);

  const handleSelectSavedAddress = (address: Address) => {
    if (address) {
      setSearchAddress(getAddressString(address, true));
    }
  };

  const handleSearchedAddress = (placeDetails: google.maps.places.PlaceResult | null) => {
    if (!placeDetails) {
      return;
    }
    const addressComponents = placeDetails.address_components;
    if (!addressComponents?.length) {
      return;
    }
    const isNearCenterExisted = centerInfos.some(({ center }) => {
      const nearCenterDistanse = geolib.getDistance(
        { latitude: placeDetails.geometry?.location?.lat() || 0, longitude: placeDetails.geometry?.location?.lng() || 0 },
        {
          latitude: center.attributes.field_prelevement_latitude,
          longitude: center.attributes.field_prelevement_longitude,
        },
      );
      return nearCenterDistanse <= NEAR_CENTER_DISTANCE_METRES;
    });

    if (isNearCenterExisted) {
      setShowPreRegistrationModal(true);
      return;
    }
    const findAddress = (addrType: string) => addressComponents.find((comp) => comp.types.includes(addrType))?.short_name;

    const number = findAddress('street_number');
    const street = findAddress('route');
    const country = findAddress('country');
    const city = findAddress('locality');
    const zip_code = findAddress('postal_code');
    const address: Address = {
      street,
      number,
      city,
      country,
      zip_code,
      country_iso: 'LU',
    };

    if (!number) {
      setShowNoNumberModal(true);
    }

    if (!zip_code) {
      setShowNoPostcodeModal(true);
      return;
    }
    setSelectedAddress(address);
    setIsSelectAddress(true);
    setNextButtonActive(true);
    setSearchAddress(getAddressString(address, true));
  };

  useEffect(() => {
    api.bnl
      .getCenters({ include: false })
      .then((res) => {
        setCenterInfos(res);
      })
      .finally(() => setIsLoading(false));
  }, [isLoading]);
  useEffect(() => {
    if (searchAddress.length > 0) {
      getPlacePredictions({
        input: searchAddress,
        language: 'en',
        componentRestrictions: {
          country: 'LU',
        },
      });
    }
  }, [searchAddress]);

  return (
    <>
      <Stack sx={stackStyles} spacing={2}>
        <Typography component='h2' variant='h2' sx={{ fontWeight: 600 }}>
          {searchAddress.length > 0 ? t('checkin.stepper.steps.address.title') : t('checkin.stepper.steps.address.search_placeholder')}
        </Typography>

        <Stack
          sx={{
            ...stackStyles,
            alignItems: 'center',
            width: {
              xs: '100%',
              sm: '70%',
              md: '50%',
              lg: '45%',
            },
          }}
          spacing={0.5}
        >
          <StyledAddressInput
            placeholder={t('checkin.stepper.steps.address.search_placeholder')}
            variant='outlined'
            value={searchAddress}
            onChange={(e) => {
              const value = e.target.value;
              setIsSelectAddress(false);

              setSearchAddress(value);
            }}
            InputProps={{
              sx: {
                borderRadius: '8px',
              },
              startAdornment: (
                <InputAdornment position='start'>
                  <ZoomIcon />
                </InputAdornment>
              ),
            }}
          />
          {placePredictions.length > 0 && !isSelectAddress && (
            <List
              sx={{
                marginTop: '24px !important',
                width: { xs: '100%', sm: '440px' },
                padding: '24px 16px',
                borderRadius: '16px',
                background: redesignColors.white,
                maxHeight: '400px',
                overflowY: 'auto',
              }}
            >
              {placePredictions.map((place) => (
                <ListItem
                  onClick={() => {
                    placesService?.getDetails(
                      {
                        placeId: place.place_id,
                        language: 'en',
                      },
                      handleSearchedAddress,
                    );
                  }}
                  sx={{ cursor: 'pointer', fontSize: '16px', borderBottom: `1px solid ${redesignColors.grey.grey3}` }}
                >
                  {place.description}
                </ListItem>
              ))}
            </List>
          )}

          {!selectedAddress && authenticatedUser && <SavedAddresses setSelectedAddress={handleSelectSavedAddress} />}

          {selectedAddress && (
            <Stack sx={{ ...stackStyles, alignItems: 'flex-start' }} spacing={2}>
              <Typography
                component='span'
                variant='button'
                sx={{
                  fontWeight: '600',
                }}
              >
                {getAddressString(selectedAddress, true)}
              </Typography>

              <TextField
                multiline
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                sx={{ width: '100%', backgroundColor: 'white', borderRadius: '8px' }}
                id='address-additional-info'
                placeholder={t('checkin.stepper.steps.address.additional_info_placeholder')}
                rows={5}
              />
            </Stack>
          )}
        </Stack>
      </Stack>

      {showNoPostcodeModal && (
        <ConfirmDialog
          open
          title={t('new_appointment.modal.no_postcode.title')}
          subtitle={t('new_appointment.modal.no_postcode.subtitle')}
          submitTitle={t('common.action.continue')}
          onClose={() => {
            setShowNoPostcodeModal(false);
          }}
          onSubmit={() => {
            setShowNoPostcodeModal(false);
          }}
        />
      )}
      {showNoNumberModal && (
        <ConfirmDialog
          open
          title={t('new_appointment.modal.no_number.title')}
          subtitle={t('new_appointment.modal.no_number.subtitle')}
          submitTitle={t('new_appointment.modal.no_number.submit')}
          cancelTitle={t('new_appointment.modal.no_number.cancel')}
          onClose={() => {
            setShowNoNumberModal(false);
          }}
          onSubmit={() => {
            setShowNoNumberModal(false);
          }}
        />
      )}
      {showPreRegistrationModal && (
        <ConfirmDialog
          open
          title={t('new_appointment.modal.pre_registration.title')}
          subtitle={t('new_appointment.modal.pre_registration.subtitle')}
          submitTitle={t('new_appointment.modal.pre_registration.submit')}
          cancelTitle={t('new_appointment.modal.pre_registration.cancel')}
          onClose={() => {
            setSelectedAddress(null);
            setSearchAddress('');
            setShowPreRegistrationModal(false);
          }}
          onSubmit={() => {
            navigate(RoutePaths['PREREGISTRATION']);
            setShowPreRegistrationModal(false);
          }}
        />
      )}
    </>
  );
};

export default AddressComponent;
