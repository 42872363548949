import { Stepper, Step as MuiStep, StepLabel, Stack, styled, StepConnector, stepConnectorClasses, stepLabelClasses, stepIconClasses, Breadcrumbs, Button } from '@mui/material';
import { FC, ReactElement, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import StepLayout from './StepLayout';
import { useTranslation } from 'react-i18next';
import type { AppointmentStep } from './types/Step';
import { useContextRedirection, useIsMobileView } from 'hooks';
import { redesignColors } from 'resources/theme/theme.colors';
import ConfirmDialog from 'components/confirm-dialog';
import RoutePaths from 'utils/RoutePaths';

interface StepperProps {
  steps: AppointmentStep[];
  handleSubmit: () => Promise<void>;
  showBreadcrumb?: boolean;
  isSubmitting?: boolean;
  breadcrumbs: ReactElement[];
  getNextLabel?: (activeStep: number) => string;
  onBack?: (activeStep: number) => void;
}

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  [`&.${stepLabelClasses.root}`]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
  },
  [`& .${stepLabelClasses.iconContainer}.Mui-completed`]: {
    background: theme.palette.common.black,
    borderRadius: '50%',
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    position: 'absolute',
    width: 'auto',
    transform: 'translateY(130%)',
  },
  [`& .${stepLabelClasses.labelContainer}.Mui-completed`]: {
    position: 'absolute',
    width: 'auto',
    transform: 'translateY(130%)',
  },
  [`& .${stepIconClasses.root}`]: {
    borderWidth: 1,
  },
  [`& .${stepIconClasses.text}`]: {
    fontSize: '14px',
    fontWeight: '400',
  },
}));

const CustomStepperConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    position: 'relative',
    top: 11,
    borderWidth: 0.5,
    margin: '0 3%',
  },
}));

const CustomStepper: FC<StepperProps> = ({ steps, showBreadcrumb, breadcrumbs, handleSubmit, getNextLabel, onBack, isSubmitting }) => {
  const { t } = useTranslation();
  const navigate = useContextRedirection();

  const [activeStep, setActiveStep] = useState(0);
  const [activeSubStep, setActiveSubStep] = useState(0);
  const [open, setOpen] = useState(false);

  const [nextButtonActive, setNextButtonActive] = useState(true);

  const isMobileView = useIsMobileView();

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };
  const setNextSubStep = () => setActiveSubStep((step) => step + 1);
  const setPrevSubStep = () => setActiveSubStep((step) => step - 1);

  const handleBack = () => {
    const subSteps = steps[activeStep]?.subSteps;
    const isFirstSubStep = activeSubStep === 0;
    if (subSteps?.length && !isFirstSubStep) {
      setActiveSubStep((step) => step - 1);
      setNextButtonActive(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setActiveSubStep(0);
    setNextButtonActive(true);
    onBack?.(activeStep);
  };

  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  const handleNext = async () => {
    if (isLastStep()) {
      await handleSubmit();
      return;
    }
    const subSteps = steps[activeStep]?.subSteps;
    const isLastSubStep = (subSteps?.length || 0) - 1 === activeSubStep;
    if (subSteps?.length && !isLastSubStep) {
      setActiveSubStep((step) => step + 1);
      return;
    }
    handleStep(activeStep + 1);
    setActiveSubStep(0);
  };

  const Render = steps[activeStep].component;

  const renderProps = {
    setNextButtonActive,
    handleBack,
    activeSubStep: steps[activeStep]?.subSteps ? steps[activeStep]?.subSteps[activeSubStep] : undefined,
    setNextSubStep,
    setPrevSubStep,
    ...steps[activeStep].props,
  };

  return (
    <Stack sx={{ padding: '16px 24px 16px', width: '100%', display: 'flex', flexGrow: 1 }}>
      {!steps[activeStep].hideStepper && (
        <Stepper
          activeStep={activeStep}
          sx={{
            alignItems: 'flex-start',
            width: {
              xs: '100%',
              sm: '100%',
              md: '95%',
            },
            mb: 4,
            '.MuiStepLabel-labelContainer span': {
              fontSize: '16px',
              transform: 'translateX(40%)',
            },
            '.MuiStepLabel-labelContainer .Mui-active': {
              fontWeight: 700,
              transform: 'translateX(40%)',
            },
            '.MuiStep-root': {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
          connector={<CustomStepperConnector />}
        >
          {steps.map((step, index) => {
            if (!step.hideStepper) {
              return (
                <MuiStep key={index}>
                  <StyledStepLabel>{isMobileView ? ' ' : step.label}</StyledStepLabel>
                </MuiStep>
              );
            }

            return null;
          })}
        </Stepper>
      )}

      <Stack sx={{ width: '100%', position: 'relative', flexGrow: 1 }} spacing={2}>
        {activeStep !== 0 && (
          <Button sx={{ color: redesignColors.red, position: 'absolute', right: 0, top: 0 }} startIcon={<CloseIcon sx={{ fill: redesignColors.red }} />} onClick={() => setOpen(true)}>
            {t('fertility.opening.cancel_button')}
          </Button>
        )}
        {/** TODO: Refactor breadcrumbs to dynamic rendering from the props */}
        {showBreadcrumb && (
          <Breadcrumbs aria-label='breadcrumbs' sx={{ color: redesignColors.dark }}>
            {breadcrumbs.map((bc) => bc)}
          </Breadcrumbs>
        )}
        {Render &&
          (steps[activeStep].wrapStep ? (
            <StepLayout
              activeNextButton={nextButtonActive}
              isSubmitting={isSubmitting}
              handleNext={handleNext}
              isLastStep={isLastStep}
              isFirstStep={isFirstStep}
              handleBack={handleBack}
              component={<Render {...renderProps} />}
              nextLabel={getNextLabel?.(activeStep)}
            />
          ) : (
            <Render {...renderProps} />
          ))}
      </Stack>
      {open && (
        <ConfirmDialog
          open
          title={t('fertility.cancel_dialog.title')}
          subtitle={t('fertility.cancel_dialog.subtitle')}
          submitTitle={t('common.action.delete')}
          onClose={() => setOpen(false)}
          onSubmit={() => {
            navigate(RoutePaths.HOME);
          }}
        />
      )}
    </Stack>
  );
};

export default CustomStepper;
