import { Box, Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import DirectionsIcon from '@mui/icons-material/Directions';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertDate, cutUTCParamsFromDateString } from 'utils/func/Date.func';
import AppointmentCancelModal from './Appointment.cancel.modal';
import { redesignColors } from 'resources/theme/theme.colors';
import { useParams } from 'react-router-dom';
import { useNotification } from 'hooks';
import { AppointmentDetail } from './types';

const AppointmentDetails: FC = () => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const { id } = useParams();

  const [appointment, setAppointment] = useState<AppointmentDetail | null>(null);

  const [modalCancelAppointment, toggleModalCancelAppointment] = useState(false);

  const formatAddress = useCallback(
    (address: any) => {
      const type =
        address?.type === 'home' ? t('appointment.list.table.address.home') : address?.type === 'work' ? t('appointment.list.table.address.work') : t('appointment.list.table.address.other');
      const formattedAddress = [
        [address.number, address.street, address.zip_code].filter((elem) => elem !== null).join(', '),
        /* @ts-ignore */
        [address.city_name, t(`countries.${address.country_iso}`)].join(' '),
      ].join(' - ');
      return `(${type}) ${formattedAddress}`;
    },
    [t],
  );

  useEffect(() => {
    if (id) {
      api.appointments
        .getAppointment(id)
        .then((res: any) => {
          setAppointment(res);
        })
        .catch(() => notification(t('appointment.list.notification.listing.error'), 'error'));
    }
  }, [notification, t, id]);

  const handleCancelationModalClose = () => {
    toggleModalCancelAppointment(false);
  };

  return (
    <BodyTemplate title={t('bodyTemplate.appointment.details')}>
      <Grid container>
        <Grid item xs={12} sm={10} md={6}>
          <Box pb={2} sx={{ borderBottom: `1px solid ${redesignColors.grey.grey3}` }}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              {appointment?.address && <Typography fontWeight='700'>{formatAddress(appointment?.address)}</Typography>}
              <IconButton>
                <DirectionsIcon color='primary' />
              </IconButton>
            </Box>
            <Typography mt={0.5}>{t('appointment.details.address_info')}</Typography>
          </Box>
          <Box pt={3} pb={2} mb={2} sx={{ borderBottom: `1px solid ${redesignColors.grey.grey3}` }}>
            <Typography fontSize='20px' fontWeight='700'>
              {!!appointment?.start && convertDate(new Date(cutUTCParamsFromDateString(appointment?.start)), true, 'MMMM d yyyy,')}
            </Typography>
            <Typography mt={0.5}>{t('appointment.details.estimated_duration', { minutes: 30 })}</Typography>
          </Box>
          <Button variant='danger' onClick={() => toggleModalCancelAppointment(true)}>
            {t('appointment.details.cancel_appointment')}
          </Button>

          {!!appointment?.patients?.length && (
            <>
              <Typography fontWeight='700' pt={2} pb={1}>
                1 {t('appointment.details.patients')}
              </Typography>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Box sx={{ pb: 2, mb: 2 }}>
                    <Typography fontWeight='700'>{appointment.patients}</Typography>
                  </Box>
                </CardContent>
              </Card>
              {/* {appointment?.patients?.map((patient) => (
                <Card sx={{ mb: 2 }}>
                  <CardContent>
                    <Box sx={{ pb: 2, mb: 2, borderBottom: !!patient?.medical_acts?.length ? `1px solid ${redesignColors.grey.grey3}` : 'none' }}>
                      <Typography fontWeight='700'>
                        {patient.first_name} {patient.last_name}
                      </Typography>
                    </Box>
                    {!!patient?.medical_acts?.length && (
                      <Box sx={{ pb: 2, mb: 2, borderBottom: !!patient?.prescriptions?.length ? `1px solid ${redesignColors.grey.grey3}` : 'none' }}>
                        <Typography pb={2} color={redesignColors.grey.grey2} fontWeight='700'>
                          {t('appointment.details.medical_procedures')}
                        </Typography>

                        <Box>
                          {patient.medical_acts.map((medicalAct) => (
                            <Chip variant='outlined' color='secondary' label={medicalAct} sx={{ mb: 1, width: 310, background: redesignColors.dialogBG }} />
                          ))}
                        </Box>
                      </Box>
                    )}
                    {!!patient?.prescriptions?.length && (
                      <Box sx={{ pb: 2, mb: 2 }}>
                        <Typography pb={2} color={redesignColors.grey.grey2} fontWeight='700'>
                          {t('appointment.details.prescriptions')}
                        </Typography>

                        <Box>
                          {patient.prescriptions.map((prescr) => (
                            <Box>{prescr.file_name}</Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              ))} */}
            </>
          )}
        </Grid>
      </Grid>
      {appointment && modalCancelAppointment && <AppointmentCancelModal appointment={appointment} onClose={handleCancelationModalClose} />}
    </BodyTemplate>
  );
};

export default AppointmentDetails;
