import { Button, Card, Grid, IconButton, SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import ContextLink from 'components/_base/ContextLink';
import { isAfter, isEqual } from 'date-fns';
import { useCurrentUser, useNotification } from 'hooks';
import { ReactComponent as RightArowIcon } from 'resources/icons/right_arrow.svg';
import { AppointmentItem, AppointmentItemKind, AppointmentItemPatientAddress } from 'models/appointment.item.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertDate, cutUTCParamsFromDateString } from 'utils/func/Date.func';
import { redesignColors } from 'resources/theme/theme.colors';
import { useNavigate } from 'react-router-dom';
import RoutePaths from 'utils/RoutePaths';
import { AppointmentResponse } from 'models/appointment.model';

const tableHeaders: GenericHeader[] = [
  {
    id: 'patients',
    label: 'appointment.list.table.patients',
  },

  {
    id: 'date',
    label: 'appointment.list.table.date',
  },
  {
    id: 'address',
    label: 'appointment.list.table.address.title',
  },
  { id: 'actions', label: undefined },
];

const AppointmentsList: FC = () => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState<AppointmentItem[]>([]);
  const { notification } = useNotification();
  const paginatorData: GenericPaginator = {
    total: 0,
    size: 5,
    page: 1,
  };
  const [paginator, setPaginator] = useState(paginatorData);

  const formatAddress = useCallback(
    (address: AppointmentItemPatientAddress['address']) => {
      const type =
        address?.type === 'home' ? t('appointment.list.table.address.home') : address?.type === 'work' ? t('appointment.list.table.address.work') : t('appointment.list.table.address.other');
      const formattedAddress = [
        [address.number, address.street, address.zip_code].filter((elem) => elem !== null).join(', '),
        /* @ts-ignore */
        [address.city_name, t(`countries.${address.country_iso}`)].join(' '),
      ].join(' - ');
      return `(${type}) ${formattedAddress}`;
    },
    [t],
  );

  useEffect(() => {
    if (currentUser) {
      api.appointments
        .getAppointments(currentUser.id)
        .then((res: AppointmentResponse[]) => {
          const appointments = res
            .sort((first: AppointmentResponse, second: AppointmentResponse) => new Date(first.date_time_from).getTime() - new Date(second.date_time_from).getTime())
            .reduce((acc: AppointmentItem[], appointment: any) => {
              const isFutureAppointment =
                isAfter(new Date(cutUTCParamsFromDateString(appointment?.date_time_from)), new Date()) || isEqual(new Date(cutUTCParamsFromDateString(appointment?.date_time_from)), new Date());
              if (isFutureAppointment) {
                const newAppointment = {
                  ...appointment,
                  patientsNames: appointment.patients.map((patient: any) => patient?.formatted_name ?? null),
                  address: appointment.address,
                  date: convertDate(cutUTCParamsFromDateString(appointment?.date_time_from), false, 'dd/MM/yyyy HH:mm'),
                };
                return [...acc, newAppointment];
              }
              return acc;
            }, [] as AppointmentItem[]);
          setAppointments(appointments);
          setPaginator((prev) => ({ ...prev, total: appointments.length }));
        })
        .catch(() => notification(t('appointment.list.notification.listing.error'), 'error'));
    }
  }, [currentUser, notification, t]);

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginator(pagination);
  };
  const cellSx = { borderBottom: `1px solid ${redesignColors.grey.grey4}` };

  return (
    <BodyTemplate
      title={t('homepage.appointment.title')}
      breadcrumbs={[
        <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate(RoutePaths.HOME)}>
          {t('new_appointment.breadcrumbs.home')}
        </Typography>,
        <Typography sx={{ color: redesignColors.grey.grey2 }}>{t('homepage.appointment.title')}</Typography>,
      ]}
      sx={{ mt: 4 }}
      action={
        <ContextLink to='BOOK_APPOINTMENT'>
          <Button variant='contained' fullWidth>
            {t('appointment.list.book.button')}
          </Button>
        </ContextLink>
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ padding: '0 !important' }}>
            <GenericTable headers={tableHeaders} pagination={paginator} setPagination={handlePaginationChange}>
              {appointments.length > 0 ? (
                appointments.map((appointment, index) => (
                  <TableRow
                    hover
                    key={index}
                    sx={{
                      border: '0px !important',
                    }}
                  >
                    <TableCell sx={cellSx}>
                      <Typography variant='body1' fontWeight='600'>
                        {appointment.patientsNames.join(', ')}
                      </Typography>
                    </TableCell>
                    <TableCell sx={cellSx}>
                      <Typography variant='body1'>{appointment.date}</Typography>
                    </TableCell>
                    <TableCell sx={cellSx}>
                      <Typography variant='body1'>{appointment.appointment_kind === AppointmentItemKind.Center ? appointment.address_center.address : formatAddress(appointment.address)}</Typography>
                    </TableCell>
                    <TableCell sx={cellSx}>
                      <ContextLink to='APPOINTMENT_DETAIL' params={{ id: appointment.id }}>
                        <IconButton>
                          <SvgIcon component={RightArowIcon} sx={{ fontSize: 28, color: redesignColors.grey.grey2 }} />
                        </IconButton>
                      </ContextLink>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <NoResultsTableRow colSpan={tableHeaders.length} noResultText={'common.noResult'} />
              )}
            </GenericTable>
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default AppointmentsList;
