import { Box, Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import api from 'api';
import { useContextRedirection, useNotification } from 'hooks';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { convertDate, cutUTCParamsFromDateString } from 'utils/func/Date.func';
import { AppointmentDetail } from './types';
import RoutePaths from 'utils/RoutePaths';

enum CancelReason {
  one = 'one',
  two = 'two',
  three = 'three',
  four = 'four',
}

const AppointmentCancelModal: FC<{
  appointment: AppointmentDetail;
  onClose: () => void;
}> = ({ appointment, onClose }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useContextRedirection();
  const { notification } = useNotification();

  const [cancelReason, setCancelReason] = useState<CancelReason | null>(null);
  const handleAppointmentCancelation = async (id: string) => {
    await api.appointments
      .cancelAppointment(parseInt(id))
      .then(() => {
        notification(t('appointment.list.notification.cancelation.success'), 'success');
        navigate(RoutePaths['APPOINTMENT']);
      })
      .catch(() => notification(t('appointment.list.notification.cancelation.error'), 'error'))
      .finally(() => {
        onClose();
      });
  };

  const handleChangeCancelReason = (selected: CancelReason) => {
    setCancelReason(selected);
  };
  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: { xs: '300px', lg: '440px' },
          maxWidth: 'fit-content',
          borderRadius: '28px',
          pt: 0,
        },
      }}
    >
      <DialogContent>
        <DialogTitle sx={{ position: 'relative', p: 0 }}>
          <Box component='h2' sx={{ fontSize: '24px', fontWeight: '500', mb: 2 }}>
            {t('appointment.details.cancel_dialog.title')}
          </Box>
          <Box sx={{ fontSize: '16px', fontWeight: '400' }}>
            {t('appointment.details.cancel_dialog.subtitle')}{' '}
            <span style={{ fontWeight: '600' }}>
              {t('appointment.details.cancel_dialog.date_title', { time: convertDate(new Date(cutUTCParamsFromDateString(appointment?.start)), true, 'MMMM d yyyy,'), names: appointment.patients })}
            </span>
          </Box>
          <Box sx={{ fontSize: '16px', fontWeight: '400', mb: 2 }}>{t('appointment.details.cancel_dialog.provide_reason_title')}</Box>
        </DialogTitle>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <RadioGroup name='cancelReason'>
              <FormControlLabel value={CancelReason.one} onChange={() => handleChangeCancelReason(CancelReason.one)} control={<Radio />} label={t('appointment.details.cancel_dialog.reason_one')} />
              <FormControlLabel value={CancelReason.two} onChange={() => handleChangeCancelReason(CancelReason.two)} control={<Radio />} label={t('appointment.details.cancel_dialog.reason_two')} />
              <FormControlLabel
                value={CancelReason.three}
                onChange={() => handleChangeCancelReason(CancelReason.three)}
                control={<Radio />}
                label={t('appointment.details.cancel_dialog.reason_three')}
              />
              <FormControlLabel value={CancelReason.four} onChange={() => handleChangeCancelReason(CancelReason.four)} control={<Radio />} label={t('appointment.details.cancel_dialog.reason_four')} />
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12} my={2} display='flex' justifyContent='flex-end'>
              <Button variant='text' sx={{ mr: 1 }} onClick={onClose}>
                {t('common.action.cancel')}
              </Button>
              <Button variant='text' sx={{ mr: 1 }} disabled={!cancelReason} onClick={() => id && handleAppointmentCancelation(id)}>
                {t('common.action.confirm')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentCancelModal;
