import { Button, Stack, styled } from '@mui/material';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface StepLayoutProps {
  handleNext: () => Promise<void> | void;
  handleBack: () => void;
  isLastStep: () => boolean;
  isFirstStep: () => boolean;
  activeNextButton: boolean;
  component: ReactElement;
  nextLabel?: string;
  isSubmitting?: boolean;
}

const stackStyles = { width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 0, justifyContent: 'space-between', gap: '50px' };

const buttonStyles = {
  mt: 0,
  border: '1px solid #7A9099',
  borderRadius: '8px',
  padding: '12px 32px 12px 32px',
};

const StyledButton = styled(Button)(({ theme, variant }) => ({
  ...buttonStyles,
  border: variant === 'outlined' ? 'solid 1px black' : 'none',
  width: '20%',
  color: 'black',
  backgroundColor: variant === 'contained' ? theme.palette.primary.main : 'none',
  '&:hover': {
    color: 'black',
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StepLayout: FC<StepLayoutProps> = ({ handleNext, handleBack, isSubmitting, isFirstStep, activeNextButton, component, nextLabel }) => {
  const { t } = useTranslation();
  const isFirst = isFirstStep();

  return (
    <Stack sx={{ ...stackStyles, justifyContent: 'space-between', minHeight: '500px', flexGrow: 1, width: '100%' }}>
      {component}

      <Stack
        sx={{
          ...stackStyles,
          justifyContent: 'space-between',
          flexDirection: !isFirst ? 'row' : 'row-reverse',
          width: {
            xs: '100%',
            sm: '70%',
          },
          mb: '3%',
        }}
      >
        {!isFirst && (
          <Button
            variant='outlined'
            onClick={handleBack}
            disabled={isSubmitting}
            sx={{
              width: {
                xs: '160px !important',
                sm: '220px !important',
              },
            }}
          >
            {t('checkin.stepper.button.previous_step')}
          </Button>
        )}

        <StyledButton
          variant='contained'
          onClick={handleNext}
          disabled={!activeNextButton || isSubmitting}
          sx={{
            width: {
              xs: '160px !important',
              sm: '220px !important',
            },
          }}
        >
          {nextLabel || t('checkin.stepper.button.next')}
        </StyledButton>
      </Stack>
    </Stack>
  );
};

export default StepLayout;
