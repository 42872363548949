import AddressComponent from 'components/appointment/Address';
import ChoosePatients from 'components/appointment/ChoosePatients';
import CustomStepper from 'components/appointment/CustomStepper';
import { useAuthenticated, useAuthenticatedUser, useContextRedirection, useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IntroductionStep from './IntroductionStep';
import AvailableSlotStep from './AvailableSlotStep';
import InformationStep from './InformationStep';
import PrescriptionsStep from 'components/appointment/PrescriptionsStep';
import SummaryStep from './SummaryStep';
import api from 'api';
import { useStore } from 'react-redux';
import { AppointmentFlowState } from 'store/reducers/newAppointmentFlow.reducer';
import RoutePaths from 'utils/RoutePaths';
import ConfirmDialog from 'components/confirm-dialog';
import { useMedicalActs } from 'hooks/useMedicalActs';
import { NEW_APPOINTMENT_SLOTS_MEDICAL_ACT_CODE } from 'utils/Constants';
import { Box, CircularProgress, Typography } from '@mui/material';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { redesignColors } from 'resources/theme/theme.colors';
import { AppointmentStep, AppointmentStepType } from 'components/appointment/types/Step';

const NewAppointment: FC = () => {
  const authenticatedUser = useAuthenticatedUser();
  const isAuthenticated = useAuthenticated();
  const { medicalActs } = useMedicalActs();
  const { getState, dispatch } = useStore();
  const { t } = useTranslation();
  const { notification } = useNotification();
  const navigate = useContextRedirection();
  const [open, setOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const steps: AppointmentStep[] = [
    {
      label: t('new_appointment.stepper.progress_labels.introduction'),
      type: AppointmentStepType.introduction,
      component: authenticatedUser ? ChoosePatients : IntroductionStep,
      wrapStep: true,
    },
    {
      label: t('new_appointment.stepper.progress_labels.search'),
      component: AddressComponent,
      type: AppointmentStepType.address,
      wrapStep: true,
      props: {
        isCheckin: false,
      },
    },
    {
      label: t('new_appointment.stepper.progress_labels.slot'),
      type: AppointmentStepType.availableSlot,
      component: AvailableSlotStep,
      subSteps: ['date', 'slots'],
      wrapStep: true,
    },
    {
      label: t('new_appointment.stepper.progress_labels.information'),
      type: AppointmentStepType.information,
      component: InformationStep,
      wrapStep: true,
    },
    {
      label: t('new_appointment.stepper.progress_labels.prescriptions'),
      type: AppointmentStepType.prescriptions,
      component: PrescriptionsStep,
      wrapStep: true,
      props: {
        isNewAppointment: true,
      },
    },
    {
      label: t('new_appointment.stepper.progress_labels.summary'),
      type: AppointmentStepType.summary,
      component: SummaryStep,
      wrapStep: true,
    },
  ];

  const breadcrumbs = [
    <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate(RoutePaths.HOME)}>
      {t('new_appointment.breadcrumbs.home')}
    </Typography>,
    <Typography sx={{ color: redesignColors.grey.grey2 }}>{t('new_appointment.title')}</Typography>,
  ];

  const submitAppointment = async () => {
    const state: AppointmentFlowState = getState().newAppointmentFlowReducer;

    const newAppointmentMedicalAct = medicalActs.find((act) => act.code === NEW_APPOINTMENT_SLOTS_MEDICAL_ACT_CODE);
    if (!newAppointmentMedicalAct) {
      notification(t('patient_details.notification.error'), 'error');
      return;
    }
    setIsSubmitting(true);
    api.appointments
      .createAppointment({ prescriptions: state.prescriptions, patients: state.patients, slot: state.slot, medicalAct: newAppointmentMedicalAct, address: state.address })
      .then(() => {
        setOpen(true);

        dispatch({
          type: NewAppointmentFlowActionType.CLEAR_NEW_APPOINTMENT_FLOW,
        });
      })
      .catch(() => {
        setError(true);
        setOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    return () => {
      dispatch({ type: NewAppointmentFlowActionType.REMOVE_ALL });
    };
  }, []);
  return authenticatedUser === undefined && isAuthenticated ? (
    <Box sx={{ margin: '25% auto 0' }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <CustomStepper
        steps={steps}
        showBreadcrumb={authenticatedUser ? false : true}
        breadcrumbs={breadcrumbs}
        handleSubmit={submitAppointment}
        isSubmitting={isSubmitting}
        onBack={(activeStepIndex) => {
          const activeStep = steps[activeStepIndex];
          if (activeStep.type === AppointmentStepType.address) {
            dispatch({ type: NewAppointmentFlowActionType.REMOVE_ADDRESS });
          }
        }}
        getNextLabel={(activeStepIndex) => {
          const activeStep = steps[activeStepIndex];

          const isLast = activeStepIndex === steps.length - 1;
          const isFirst = activeStepIndex === 0;
          if (isLast) {
            return t('common.action.confirm');
          }
          if ((isFirst && isAuthenticated) || [AppointmentStepType.prescriptions, AppointmentStepType.information].includes(activeStep.type)) {
            return t('common.action.continue');
          }

          return t('checkin.stepper.button.next');
        }}
      />
      <ConfirmDialog
        hideCancel
        title={error ? t('new_appointment.final_dialog.error_title') : t('new_appointment.final_dialog.success_title')}
        subtitle={error ? t('new_appointment.final_dialog.error_subtitle') : t('new_appointment.final_dialog.success_subtitle')}
        submitTitle={t('common.action.understood')}
        open={open}
        onClose={() => navigate(RoutePaths.HOME)}
        onSubmit={() => navigate(RoutePaths.HOME)}
      />
    </>
  );
};

export default NewAppointment;
