import { Button, Stack, Typography } from '@mui/material';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { ReactComponent as RoundedHomeIcon } from 'resources/icons/rounded_home.svg';
import { ReactComponent as RoundedWorkIcon } from 'resources/icons/rounded_work.svg';
import { ReactComponent as RoundedOtherIcon } from 'resources/icons/rounded_other_address.svg';
import api from 'api';
import { Address } from 'models/profile.model';
import { getAddressString } from './utils';

interface SavedAddressProps {
  setSelectedAddress: (address: Address) => void;
}

//Only for logged in user
const SavedAddresses: FC<SavedAddressProps> = ({ setSelectedAddress }) => {
  const [savedAddresses, setSavedAddresses] = useState<Address[]>([]);

  useEffect(() => {
    api.users.getMe().then((response) => {
      const { home, work } = response?.addresses;
      let addresses = [];
      if (home) {
        addresses.push(home);
      }
      if (work) {
        addresses.push(work);
      }

      setSavedAddresses(addresses);
    });
  }, [setSavedAddresses]);

  const handleClick = (address: Address) => {
    setSelectedAddress(address);
  };

  const getIcon = (address: Address) => {
    if (address?.id) {
      return address?.company ? <RoundedWorkIcon /> : <RoundedHomeIcon />;
    }
    return <RoundedOtherIcon />;
  };

  return (
    <Stack
      sx={{
        width: '100%',
        marginTop: '24px !important',
      }}
      spacing={2}
    >
      {savedAddresses.map((address) => (
        <Button
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
            padding: '8px 0px 8px',
            textDecoration: 'none',
          }}
          key={address.id}
          onClick={() => handleClick(address)}
        >
          {getIcon(address)}
          <Stack textAlign='left'>
            <Typography fontSize='16px' fontWeight='500'>
              {getAddressString(address)}
            </Typography>
            <Typography fontSize='16px' sx={{ color: 'gray' }}>
              {address.zip_code}, {address.city}
            </Typography>
          </Stack>
        </Button>
      ))}
    </Stack>
  );
};

export default SavedAddresses;
