import { Gender } from 'models/profile.model';
import { AppointmentSlot } from './appointment.slots.model';

export type AppointmentPatient = {
  patient_id?: string | null;
  cns?: string;
  birth_date?: string | null | Date | undefined;
  birth_name?: string;
  gender?: string;
  formatted_name?: string;
  last_name?: string;
  first_name: string;
  phone?: string;
  mobile_phone?: string;
  email?: string;
  fasting: boolean;
  medical_acts: string[];
  // prescriptions: { file_name?: string; file_content: string; file_type?: string }[];
  prescriptions: any[];
  comment?: string;
};

export type RdvPatient = {
  id?: number;
  firstname: string;
  birthname: string;
  lastname?: string;
  birthdate: string | Date;
  email: string;
  verifyemail: string;
  phone: string;
  cns?: string;
  gender: string;
};

export type RdvCoordInfo = {
  city: string;
  country: string;
  country_code: string;
  display_name: string;
  lat: number;
  lon: number;
  street_number: string;
  street_name: string;
  postcode: string;
  state: string;
  comment: string;
};

export type RdvOrdonnance = {
  file_name: string;
  file_type: string;
  file_content: string;
};

export type RdvAnalyse = {
  id: number;
  name: string;
  code: string;
  description: string;
  duration_act: number;
  installation_duration: number;
  nurse_duration: number;
  patient_duration: number;
  expiration_time: number;
  only_center: boolean;
  with_ordo: boolean;
  center_room: string;
};

export type RdvPrescription = {
  id: string; // nanoid uses on front only
  patient: RdvPatient;
  analyses: RdvAnalyse[];
  ordonnances: RdvOrdonnance[];
};

export type RdvCenter = {
  id: number;
  name: string;
  actif: boolean;
  color: string;
  address: string;
  coord: {
    type: string;
    coordinates: [number, number];
  };
  imageUrl: string;
  distance: number;
  nextAvailableSlot?: AppointmentSlot;
};

export type Rdv = {
  slot: string | null;
  coordInfo: RdvCoordInfo | null;
  center: RdvCenter | null;
  prescriptions: RdvPrescription[];
};

export type Appointment = {
  creator:
    | {
        last_name: string | undefined;
        first_name: string | undefined;
        phone?: string;
        mobile_phone?: string;
        email?: string;
      }
    | undefined;
  comment: string;
  start: string;
  id: number;
  address: {
    type: string; // home | other?
    number: string;
    street: string;
    complement: string;
    city_name: string;
    company: string;
    zip_code: string;
    country_iso: string;
  };
  patients: AppointmentPatient[];
  prescription_id: number | undefined;
};
export type AppointmentResponse = Appointment & { date_time_from: string };

export const basicUnknownPatient: AppointmentPatient = {
  patient_id: null,
  cns: '',
  birth_date: null,
  birth_name: '',
  gender: Gender.FEMALE,
  last_name: '',
  first_name: '',
  phone: '',
  mobile_phone: '',
  email: '',
  fasting: false,
  medical_acts: [],
  prescriptions: [],
  comment: '',
};
