import { FC, useEffect } from 'react';
import PatientInfoCard from './components/PatientInfoCard';
import { Stack } from '@mui/material';
import { useSelector, useStore } from 'react-redux';
import { useAuthenticatedUser } from 'hooks';
import { GlobalState } from 'store/reducers';
import { AppointmentFlowState } from 'store/reducers/newAppointmentFlow.reducer';

interface InformationStepProps {
  setNextButtonActive: (state: boolean) => void;
}

const InformationStep: FC<InformationStepProps> = ({ setNextButtonActive }) => {
  const { getState } = useStore();
  const authenticatedUser = useAuthenticatedUser();

  const patients = useSelector((state: GlobalState) => (state.newAppointmentFlowReducer as AppointmentFlowState).patients);

  useEffect(() => {
    const isSomeFormInvalid = patients.some((pat) => pat.selected && pat?.isInvalidForm);

    if (isSomeFormInvalid) {
      setNextButtonActive(false);
    } else {
      setNextButtonActive(true);
    }
  }, [patients]);
  const renderPatients = () => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage || !storage.patients) return;

    const cards = [];

    for (let i = 0; i < storage.patients.length; i++) {
      if (storage.patients[i].selected) {
        cards.push(<PatientInfoCard key={'patient' + i} type={storage.patients[i].type} customId={storage.patients[i].id} setNextButtonActive={setNextButtonActive} />);
      }
    }

    return cards;
  };

  const renderAdults = () => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage || !storage.adultsNumber) return;

    const cards = [];

    for (let i = 0; i < storage.adultsNumber; i++) {
      cards.push(<PatientInfoCard key={'adult' + i} type='Adult' customId={'adult' + i} setNextButtonActive={setNextButtonActive} />);
    }

    return cards;
  };

  const renderChild = () => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage || !storage.childrenNumber) return;

    const cards = [];

    for (let i = 0; i < storage.childrenNumber; i++) {
      cards.push(<PatientInfoCard key={'child' + i} type='Child' customId={'child' + i} setNextButtonActive={setNextButtonActive} />);
    }

    return cards;
  };

  return (
    <Stack
      spacing={2}
      sx={{
        width: {
          xs: '100%',
          sm: '70%',
          md: '660px',
        },
      }}
    >
      {authenticatedUser && renderPatients()}
      {!authenticatedUser && renderAdults()}
      {!authenticatedUser && renderChild()}
    </Stack>
  );
};

export default InformationStep;
